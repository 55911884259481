import { Slider } from '../modules/slider';

const sections = document.querySelectorAll('.trainers');

if (sections.length > 0) {
    sections.forEach(section => {
        const slider = new Slider({
            el: section,
            loop: true,
            blockLastSlides: false,
            breakpoints: {
                0: {
                    slidesPerView: 1.5,
                },
                992: {
                    destroy: true,
                },
            },
        });
    });
}
